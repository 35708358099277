import React from "react"

import Profile from "@templates/Profile"
import SEO from "@components/seo"

const LocationProfilePage = () => {
  return (
    <>
      <SEO title="User Profile" />
      <Profile />
    </>
  )
}
export default LocationProfilePage
