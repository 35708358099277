import React from "react"
import Layout from "@components/layout"

import "./styles.less"

// import { Card, CardBody, Nav, TabContent, TabPane, Button } from "reactstrap"

const ProfileTemplate = () => {
  return <Layout title={"Hello User"}></Layout>
}

export default ProfileTemplate
